import PropTypes from 'prop-types'
import classnames from 'classnames'
import WHITE_CHECK_ICON from '../../assets/icons/white-check.svg'
import './Checkbox.scss'
import { forwardRef } from 'react'

const Checkbox = forwardRef(
  (
    { checked, onClick, div, disabled, hasError, className, style, ...props },
    ref
  ) => {
    // The div option should only be used when nesting a checkbox element inside
    // a parent element that's also a button with an identical onClick handler.
    // The option should only be used in such edge cases.
    const Tag = div ? 'div' : 'button'

    return (
      <Tag
        ref={ref}
        type="button"
        onClick={onClick && !disabled ? () => onClick(!checked) : () => {}}
        className={classnames(
          'common__checkbox',
          {
            'common__checkbox--checked': checked,
            'common__checkbox--error': hasError && !checked,
            'common__checkbox--disabled': disabled,
          },
          className
        )}
        style={style}
        role="checkbox"
        aria-checked={checked}
        {...props}
      >
        <img
          src={WHITE_CHECK_ICON}
          className={classnames('common__checkbox__check', {
            'common__checkbox__check--unchecked': !checked,
          })}
          alt="Checkbox"
        />
      </Tag>
    )
  }
)

Checkbox.displayName = 'CheckBox'

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  div: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Checkbox
