import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { captureEvent } from '../errorTracking'
import Page from './Page'
import { H1, P } from './Typography'
import WhiteBox from './WhiteBox'

const ErrorBoundary = () => {
  const [t] = useTranslation()
  const error = useRouteError()

  useEffect(() => {
    if (error) {
      captureEvent({
        message: 'Caught by ErrorBoundary',
        extra: { error },
      })
    }
  }, [error])

  let errorMessage

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.error?.message || error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:ERROR_BOUNDARY.TITLE')}</H1>
        <details className="common__error-boundary__details">
          <summary className="common__error-boundary__summary">
            {t('ANONYMOUS:ERROR_BOUNDARY.SUMMARY')}
          </summary>
          <div className="common__error-boundary__body">
            <P>{errorMessage}</P>
          </div>
        </details>
      </WhiteBox>
    </Page>
  )
}

export default ErrorBoundary
