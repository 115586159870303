import { useCallback, useState } from 'react'
import { captureEvent } from '../errorTracking'

export const usePersistentState = (key, initialState) => {
  const [state, setState] = useState(() => {
    const stored = window.localStorage.getItem(key)
    let storedOrDefault = initialState
    if (stored) {
      try {
        storedOrDefault = JSON.parse(stored)
      } catch (error) {
        console.error(`Failed to parse stored state for key "${key}"`)
        captureEvent({
          message: 'usePersistentState: failed to parse stored state',
          extra: { key, error },
        })
      }
    }
    return storedOrDefault
  })

  return [
    state,
    useCallback(
      (newState) => {
        window.localStorage.setItem(key, JSON.stringify(newState))
        setState(newState)
      },
      [key]
    ),
  ]
}
