import ContainerSpinner from '@hsl-fi/container-spinner'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Page from '../../../common/components/Page'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  BENEFIT_TYPE_SEASON_TICKET,
} from '../../../common/constants'
import { __IS_LOCALHOST__ } from '../../../common/environments'
import { captureEvent } from '../../../common/errorTracking'
import { getFullAddress, isDomicileWithinHsl } from '../../../common/helpers'
import useInterval from '../../../common/hooks/useInterval'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import BeneficiarySuccess from './BeneficiarySuccess'

const BeneficiarySuccessScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const zone = searchParams.get('zone')
  const inviteId = searchParams.get('inviteId')
  const benefitType = searchParams.get('benefitType')
  const [profileFetchAttempts, setProfileFetchAttempts] = useState(0)
  const [userFetchAttempts, setUserFetchAttempts] = useState(0)
  const [inviteFetchAttempts, setInviteFetchAttempts] = useState(0)
  const [isFetchingUser, setIsFetchingUser] = useState(false)
  const [isFetchingProfile, setIsFetchingProfile] = useState(false)
  const [isFetchingInvite, setIsFetchingInvite] = useState(false)
  const [profile, setProfile] = useState()
  const [user, setUser] = useState()
  const address = getFullAddress(profile)
  const domicileClasses = profile ? profile.domicileClasses : []
  const [invite, setInvite] = useState()
  const [firstBenefit] = invite?.benefits ?? []
  const hasFinishedPollingForProfile =
    benefitType === BENEFIT_TYPE_SEASON_TICKET
      ? Boolean(address) || profileFetchAttempts >= 7
      : Boolean(profile)
  const hasFinishedPollingForUser =
    benefitType === BENEFIT_TYPE_SEASON_TICKET
      ? Boolean(user) || userFetchAttempts >= 7
      : Boolean(user)
  const hasFinishedPollingInvite =
    benefitType === BENEFIT_TYPE_ANNUAL_BALANCE ||
    benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE
      ? Boolean(firstBenefit?.grantedAmount) || inviteFetchAttempts > 7
      : Boolean(invite)

  const fetchInvite = async () => {
    try {
      if (isFetchingInvite || hasFinishedPollingInvite) {
        return
      }

      setIsFetchingInvite(true)
      setInviteFetchAttempts(inviteFetchAttempts + 1)
      setInvite(await apiStore.endpoints.invite.getInviteById(inviteId))

      setIsFetchingInvite(false)
    } catch (e) {
      captureEvent({
        message: 'Beneficiary strong authentication error (fetch invite)',
        extra: {
          userId: user?.sub,
          inviteId,
          zone,
          error: e,
        },
      })

      uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
    }
  }

  const fetchProfile = async () => {
    try {
      if (isFetchingProfile || hasFinishedPollingForProfile) {
        return
      }

      setIsFetchingProfile(true)
      setProfileFetchAttempts(profileFetchAttempts + 1)
      setProfile(await apiStore.endpoints.user.getProfile())

      setIsFetchingProfile(false)
    } catch (e) {
      captureEvent({
        message: 'Beneficiary strong authentication error (fetch profile)',
        extra: {
          userId: user?.sub,
          zone,
          inviteId,
          error: e,
        },
      })

      uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
    }
  }

  const fetchUser = async () => {
    try {
      if (isFetchingUser || hasFinishedPollingForUser) {
        return
      }

      setIsFetchingUser(true)
      setUserFetchAttempts(userFetchAttempts + 1)

      const userResponse = await apiStore.fetchUser()

      setUser(userResponse)
    } catch (e) {
      captureEvent({
        message: 'Beneficiary strong authentication error (fetch user)',
        extra: {
          zone,
          inviteId,
          error: e,
        },
      })

      uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
    } finally {
      setIsFetchingUser(false)
    }
  }

  // We need to give the business back-end enough time to save the address
  // that was retrieved from Suomi.fi, which is why we need to keep polling
  // the profile and user endpoints until the address and benefit become
  // available.
  useInterval(fetchUser, 1500)
  useInterval(fetchProfile, 1500)
  useInterval(fetchInvite, 1500)

  const isInviteAccepted = invite?.accepted

  if (
    !__IS_LOCALHOST__ &&
    benefitType === BENEFIT_TYPE_SEASON_TICKET &&
    hasFinishedPollingInvite &&
    !isInviteAccepted
  ) {
    return <Redirect route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR} />
  }

  const hasNoBenefits = !(user?.['etb/claims/benefits'] || []).length

  if (
    !__IS_LOCALHOST__ &&
    benefitType === BENEFIT_TYPE_SEASON_TICKET &&
    hasFinishedPollingForUser &&
    hasNoBenefits
  ) {
    return <Redirect route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR} />
  }

  if (
    !__IS_LOCALHOST__ &&
    hasFinishedPollingForProfile &&
    benefitType === BENEFIT_TYPE_SEASON_TICKET &&
    !isDomicileWithinHsl(domicileClasses)
  ) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR}
        query={{ reason: 'outside-hsl-zone' }}
      />
    )
  }

  return (
    <ContainerSpinner visible={!hasFinishedPollingForProfile}>
      <Page>
        <BeneficiarySuccess
          benefitType={benefitType}
          benefit={firstBenefit}
          zone={zone}
        />
      </Page>
    </ContainerSpinner>
  )
}

export default observer(BeneficiarySuccessScreen)
