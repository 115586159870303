import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  ButtonGroup,
  ExternalLinkButton,
} from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import { USER_ROLES } from '../../../common/constants'
import { COMPANY_ADMIN_LOGIN_URL, SUPERVISOR_LOGIN_URL } from '../constants'

const UnauthorizedScreen = () => {
  const [t] = useTranslation()

  const [searchParams] = useSearchParams()
  const from = searchParams.get('from')
  const role = searchParams.get('role')

  const encodedFrom = encodeURIComponent(from)
  const redirectTo = encodeURIComponent(`authenticated?from=${encodedFrom}`)

  const loginUrl = `${role === USER_ROLES.HSL_ADMIN ? SUPERVISOR_LOGIN_URL : COMPANY_ADMIN_LOGIN_URL}?redirectTo=${redirectTo}`

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:UNAUTHORIZED_SCREEN.HEADING')}</H1>
        <P>{t('ANONYMOUS:UNAUTHORIZED_SCREEN.BODY')}</P>
        <ButtonGroup>
          <ExternalLinkButton href={loginUrl}>
            {t('COMMON:AUTHENTICATION.LOG_IN')}
          </ExternalLinkButton>
          <ExternalLinkButton secondary href={t('COMMON:HSL_HOME_URL')}>
            {t('COMMON:GO_TO_HSL_FI')}
          </ExternalLinkButton>
        </ButtonGroup>
      </WhiteBox>
    </Page>
  )
}

export default UnauthorizedScreen
