import PropTypes from 'prop-types'
import classnames from 'classnames'
import ErrorBox from './ErrorBox'
import WARNING_ICON from '../../assets/icons/warning.svg'
import './FormErrors.scss'

const FormErrors = ({ errors, ...props }) => (
  <ErrorBox {...props}>
    <div className="common__form-errors">
      <div className="common__form-errors__left">
        <img
          src={WARNING_ICON}
          className="common__form-errors__icon"
          alt="Warning"
        />
      </div>
      <div className="common__form-errors__right">
        <ul
          className={classnames('common__form-errors__ul', {
            'common__form-errors__ul--only-one-row': errors.length === 1,
          })}
        >
          {errors.map((error, index) => (
            <li key={index} className="common__form-errors__li">
              {error}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </ErrorBox>
)

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default FormErrors
