import { useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { times } from '../utility'
import TextInput from './TextInput'
import InputLabel from './InputLabel'
import './PinInput.scss'

const PinInput = ({
  label,
  name,
  digits,
  value,
  onChange,
  hasError,
  required,
  style,
}) => {
  const values = value.split('')
  const refs = useRef(times(digits).map(() => createRef()))

  return (
    <div className="common__pin-input" style={style}>
      {Boolean(label) && (
        <InputLabel required={required} htmlFor={`${name}__0`}>
          {label}
        </InputLabel>
      )}
      <div className="common__pin-input__row">
        {times(digits).map((index) => (
          <TextInput
            key={index}
            ref={refs.current[index]}
            className="common__pin-input__field"
            id={`${name}__${index}`}
            value={values[index] || ''}
            type="number"
            maxLength={1}
            onFocus={({ target }) => target.select()}
            onChange={({ target }) => {
              const newValues = [...values]

              newValues[index] = target.value

              const newValue = newValues.join('').substring(0, digits)

              onChange(newValue)

              if (target.value.length === 0) {
                if (index !== 0) {
                  // Focus on previous input
                  refs.current[index - 1].current.focus()
                }

                return
              }

              const nextIndex = index + 1
              const hasMoreInputs = refs.current.length > nextIndex

              if (hasMoreInputs) {
                // Focus on next input
                refs.current[index + 1].current.focus()
              }
            }}
            onKeyDown={({ key, target }) => {
              if (
                key === 'Backspace' &&
                index !== 0 &&
                target.value.length === 0
              ) {
                refs.current[index - 1].current.focus()
              }
            }}
            hasError={hasError}
            inputStyle={{
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: 'center',
              width: '41px',
            }}
            ariaLabel={`PIN ${index + 1}/${digits}`}
          />
        ))}
      </div>
    </div>
  )
}

PinInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  digits: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.object,
}

export default PinInput
