import { useQuery } from '@tanstack/react-query'
import apiStore from '../../../common/stores/apiStore'

const useBenefitsQuery = ({ companyId, enabled, ...filters }) => {
  return useQuery({
    queryKey: ['benefits', companyId, filters],
    queryFn: async () => {
      return await apiStore.endpoints.beneficiary.getBenefits({
        companyId,
        ...filters,
      })
    },
    enabled,
  })
}

export default useBenefitsQuery
