import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import Page from '../../../common/components/Page'
import { Button } from '../../../common/components/Button'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'

const LoginUnavailableScreen = ({ history }) => {
  const [t] = useTranslation()

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:LOGIN_UNAVAILABLE_SCREEN.HEADING')}</H1>
        <P>{t('ANONYMOUS:LOGIN_UNAVAILABLE_SCREEN.PLEASE_TRY_AGAIN_LATER')}</P>
        <Button onClick={() => history.goBack()}>
          {t('COMMON:BACK_TO_PREVIOUS_PAGE')}
        </Button>
      </WhiteBox>
    </Page>
  )
}

LoginUnavailableScreen.propTypes = {
  history: PropTypes.object,
}

export default LoginUnavailableScreen
