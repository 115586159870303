import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ExternalLinkButton } from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { A, H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import { captureEvent } from '../../../common/errorTracking'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'

const StrongAuthenticationErrorScreen = () => {
  const [t] = useTranslation()
  const serverError = t('COMMON:ERRORS.SERVER_ERROR')
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')

  useEffect(() => {
    const sendErrorReportToSentry = async () => {
      try {
        const user = await apiStore.fetchUser()

        captureEvent({
          message: 'Self-service strong authentication error',
          extra: {
            userId: user?.sub,
          },
        })
      } catch (e) {
        uiMessageStore.addError(serverError, e)
      }
    }

    sendErrorReportToSentry()
  }, [serverError])

  return (
    <Page>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:STRONG_AUTHENTICATION_ERROR_SCREEN.HEADING')}</H1>
        <P>{t('SELF_SERVICE:STRONG_AUTHENTICATION_ERROR_SCREEN.BODY_1')}</P>
        <P>
          <Trans
            i18nKey="SELF_SERVICE:STRONG_AUTHENTICATION_ERROR_SCREEN.BODY_2"
            components={{ a: <A target="_blank" rel="noopener noreferrer" /> }}
            values={{
              url: t(
                'SELF_SERVICE:STRONG_AUTHENTICATION_ERROR_SCREEN.CONTACT_INFORMATION_URL'
              ),
            }}
          />
        </P>
        <ExternalLinkButton
          href={`/api/self-service/strong-authentication?inviteId=${inviteId}`}
        >
          {t(
            'SELF_SERVICE:STRONG_AUTHENTICATION_ERROR_SCREEN.TRY_AGAIN_BUTTON_LABEL'
          )}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default observer(StrongAuthenticationErrorScreen)
