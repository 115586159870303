import { useSearchParams } from 'react-router-dom'
import ContainerSpinner from '@hsl-fi/container-spinner'
import BeneficiarySuccess from './BeneficiarySuccess'
import Page from '../../../common/components/Page'
import { BENEFIT_TYPE_SEASON_TICKET } from '../../../common/constants'

// Mockup of BeneficiarySuccessScreen to facilitate testing and development.
//
// Examples:
//
// http://localhost:3000/fi/edunsaaja/valmis/mock?benefitType=SEASON_TICKET&zone=AB
// http://localhost:3000/fi/edunsaaja/valmis/mock?benefitType=ANNUAL_BALANCE
// http://localhost:3000/fi/edunsaaja/valmis/mock?benefitType=BUSINESS_TRIP_ANNUAL_BALANCE

const BeneficiarySuccessScreenMock = () => {
  const [searchParams] = useSearchParams()
  const zone = searchParams.get('zone')
  const benefitType = searchParams.get('benefitType')
  const benefit = createMockBenefit(benefitType)

  return (
    <ContainerSpinner visible={false}>
      <Page>
        <BeneficiarySuccess
          benefitType={benefitType}
          zone={zone}
          benefit={benefit}
        />
      </Page>
    </ContainerSpinner>
  )
}

const createMockBenefit = (benefitType) => {
  if (benefitType === BENEFIT_TYPE_SEASON_TICKET) {
    return undefined
  }

  return {
    grantedAmount: 10,
    validFrom: new Date(),
    validTo: undefined,
  }
}

export default BeneficiarySuccessScreenMock
