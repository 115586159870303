import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import BACK_ICON from '../../../assets/icons/close-white.svg'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { EN_DASH } from '../../../common/constants'
import Spinner from '../../../common/components/Spinner'
import './GuestCompanyAdminBar.scss'
import useCurrentCompany from '../../company/components/hooks/useCurrentCompany'

const GuestCompanyAdminBar = () => {
  const [t] = useTranslation()
  const [shouldClose, setShouldClose] = useState(false)
  const { data: company, isLoading: isFetchingCompany } = useCurrentCompany()

  const returnToSupervisorUi = () => {
    setShouldClose(true)
  }

  if (shouldClose) {
    return <Redirect route={ROUTES.SUPERVISOR.COMPANIES} />
  }

  return (
    <div className="supervisor__guest-company-admin-bar">
      <div className="supervisor__guest-company-admin-bar__left">
        {isFetchingCompany ? (
          <Spinner size={20} centered />
        ) : (
          <span className="supervisor__guest-company-admin-bar__description">
            {t('SUPERVISOR:GUEST_COMPANY_ADMIN_BAR.DESCRIPTION', {
              company: company?.name ?? EN_DASH,
            })}
          </span>
        )}
      </div>
      <div className="supervisor__guest-company-admin-bar__left">
        <button
          type="button"
          className="supervisor__guest-company-admin-bar__back-button"
          onClick={() => returnToSupervisorUi()}
        >
          <span className="supervisor__guest-company-admin-bar__back-label">
            {t('SUPERVISOR:GUEST_COMPANY_ADMIN_BAR.BACK')}
          </span>
          <img
            src={BACK_ICON}
            className="supervisor__guest-company-admin-bar__back-icon"
            role="button"
            alt={t('SUPERVISOR:GUEST_COMPANY_ADMIN_BAR.BACK')}
          />
        </button>
      </div>
    </div>
  )
}

export default observer(GuestCompanyAdminBar)
