import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ELECTRONIC_INVOICE } from '../../../common/components/BillingMethodInput'
import {
  Button,
  ButtonGroup,
  LinkButton,
} from '../../../common/components/Button'
import CheckboxWithLabel from '../../../common/components/CheckboxWithLabel'
import FormErrors from '../../../common/components/FormErrors'
import Hr from '../../../common/components/Hr'
import LabelAndValueList from '../../../common/components/LabelAndValueList'
import Page from '../../../common/components/Page'
import { H1, H2, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import { pushTagEvent } from '../../../common/tagManager/tagManager'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { validateSelfServiceAgreement } from '../helpers/validateSelfServiceForm'
import applicationFormStore from '../stores/applicationFormStore'
import './ApplicationConfirmationScreen.scss'
import { formatNumberOfEmployees } from './ApplicationScreen'
import useSendNewCompanyApplicationMutation from './queries/useSendNewCompanyApplicationMutation'

const blankAgreements = {
  companyPermission: false,
  termsOfService: false,
  privacyPolicy: false,
}

const ApplicationConfirmationScreen = () => {
  const [t] = useTranslation()
  const [agreements, setAgreements] = useState(blankAgreements)
  const [errors, setErrors] = useState({})

  const {
    mutateAsync: sendNewCompanyApplication,
    isPending: isSendNewCompanyApplicationPending,
    isSuccess: isSendNewCompanyApplicationSuccess,
  } = useSendNewCompanyApplicationMutation()

  const submit = async (form) => {
    const errors = validateSelfServiceAgreement(agreements, t)

    setErrors(errors)

    if (hasErrors(errors)) {
      return
    }

    const payload = {
      companyName: form.companyName,
      businessId: form.businessId,
      numberOfEmployees: form.numberOfEmployees,
      officeAddress: {
        street: form.officeStreetAddress,
        postalCode: form.officePostalCode,
        city: form.officeCity,
      },
      mailingAddress: {
        street: form.useOfficeAsMailingAddress
          ? form.officeStreetAddress
          : form.mailingStreetAddress,
        postalCode: form.useOfficeAsMailingAddress
          ? form.officePostalCode
          : form.mailingPostalCode,
        city: form.useOfficeAsMailingAddress
          ? form.officeCity
          : form.mailingCity,
      },
      billingMethod: form.billingMethod,
      billingIntermediary: form.billingIntermediary,
      electronicInvoiceAddress: form.electronicInvoiceAddress,
      contactName: form.contactName,
      contactPhoneNumber: form.contactPhoneNumber,
      contactWorkEmail: form.contactWorkEmail,
    }

    pushTagEvent({ event: 'registration' })

    await sendNewCompanyApplication({ payload })
    applicationFormStore.resetForm()
  }

  if (isSendNewCompanyApplicationSuccess) {
    return <Redirect route={ROUTES.SELF_SERVICE.APPLICATION_SENT} />
  }

  return (
    <Page>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:APPLICATION_CONFIRMATION_SCREEN.HEADING')}</H1>
        <P>{t('SELF_SERVICE:APPLICATION_CONFIRMATION_SCREEN.DESCRIPTION')}</P>
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">
          {t('SELF_SERVICE:SELF_SERVICE_SCREEN.COMPANY_DETAILS')}
        </H2>
        <LabelAndValueList
          items={[
            {
              label: t('COMMON:COMPANY_NAME'),
              value: applicationFormStore.form.companyName,
            },
            {
              label: t('COMMON:BUSINESS_ID'),
              value: applicationFormStore.form.businessId,
            },
            {
              label: t('SELF_SERVICE:SELF_SERVICE_SCREEN.NUMBER_OF_EMPLOYEES'),
              value: formatNumberOfEmployees(
                applicationFormStore.form.numberOfEmployees,
                t
              ),
            },
          ]}
        />
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">{t('COMMON:OFFICE_ADDRESS')}</H2>
        <LabelAndValueList
          items={[
            {
              label: t('COMMON:STREET_ADDRESS'),
              value: applicationFormStore.form.officeStreetAddress,
            },
            {
              label: t('COMMON:POSTAL_CODE'),
              value: applicationFormStore.form.officePostalCode,
            },
            {
              label: t('COMMON:CITY'),
              value: applicationFormStore.form.officeCity,
            },
          ]}
        />
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        {applicationFormStore.form.useOfficeAsMailingAddress ? (
          <LabelAndValueList
            items={[
              {
                label: t('SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS'),
                value: t(
                  'SELF_SERVICE:SELF_SERVICE_SCREEN.SAME_AS_OFFICE_ADDRESS'
                ),
              },
            ]}
            labelClassName="self-service__application-confirmation-screen__label-as-subheading"
          />
        ) : (
          <>
            <H2 fontSize="mormal">
              {t('SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS')}
            </H2>
            <LabelAndValueList
              items={[
                {
                  label: t('COMMON:STREET_ADDRESS'),
                  value: applicationFormStore.form.mailingStreetAddress,
                },
                {
                  label: t('COMMON:POSTAL_CODE'),
                  value: applicationFormStore.form.mailingPostalCode,
                },
                {
                  label: t('COMMON:CITY'),
                  value: applicationFormStore.form.mailingCity,
                },
              ]}
            />
          </>
        )}
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">{t('COMMON:BILLING_METHODS.BILLING_METHOD')}</H2>
        <P style={{ marginBottom: '10px' }}>
          {applicationFormStore.form.billingMethod === ELECTRONIC_INVOICE
            ? t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE')
            : t('COMMON:BILLING_METHODS.PAPER_INVOICE_HELP_TEXT')}
        </P>
        {applicationFormStore.form.billingMethod === ELECTRONIC_INVOICE && (
          <LabelAndValueList
            items={[
              {
                label: t('COMMON:BILLING_METHODS.BILLING_INTERMEDIARY'),
                value: applicationFormStore.form.billingIntermediary,
              },
              {
                label: t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE_ADDRESS'),
                value: applicationFormStore.form.electronicInvoiceAddress,
              },
            ]}
          />
        )}
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">
          {t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT')}
        </H2>
        <LabelAndValueList
          items={[
            {
              label: t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT_NAME'),
              value: applicationFormStore.form.contactName,
            },
            {
              label: t('COMMON:WORK_EMAIL'),
              value: applicationFormStore.form.contactWorkEmail,
            },
            {
              label: t('COMMON:PHONE'),
              value: applicationFormStore.form.contactPhoneNumber,
            },
          ]}
        />
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <CheckboxWithLabel
          className="mb-md"
          checked={agreements.companyPermission}
          onClick={() =>
            setAgreements({
              ...agreements,
              companyPermission: !agreements.companyPermission,
            })
          }
          label={t(
            'SELF_SERVICE:APPLICATION_CONFIRMATION_SCREEN.COMPANY_PERMISSION_CHECKBOX_LABEL'
          )}
          hasError={Boolean(errors.companyPermission)}
        />
        {hasErrors(errors) && (
          <FormErrors
            errors={Object.keys(errors).map((key) => errors[key])}
            style={{ marginBottom: '30px' }}
          />
        )}
        <ButtonGroup>
          <Button
            onClick={() => submit(applicationFormStore.form)}
            loading={isSendNewCompanyApplicationPending}
          >
            {t('COMMON:SUBMIT')}
          </Button>
          <LinkButton secondary route={ROUTES.SELF_SERVICE.HOME}>
            {t('COMMON:PREVIOUS')}
          </LinkButton>
        </ButtonGroup>
      </WhiteBox>
    </Page>
  )
}

const hasErrors = (errors) => Boolean(Object.keys(errors).length)

export default observer(ApplicationConfirmationScreen)
