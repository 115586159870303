import PropTypes from 'prop-types'
import classnames from 'classnames'
import './FiftyFifty.scss'

const FiftyFifty = ({
  left,
  right,
  className,
  leftClassName,
  rightClassName,
  leftStyle,
  rightStyle,
  reverseOnMobile,
  hideSeparator,
  style,
  leftProps,
  rightProps,
}) => (
  <div
    className={classnames('common__fifty-fifty', className, {
      'common__fifty-fifty--reverse-on-mobile': reverseOnMobile,
    })}
    style={style}
  >
    <div
      className={classnames(
        'common__fifty-fifty__left',
        { 'common__fifty-fifty__left--hide-separator': hideSeparator },
        leftClassName
      )}
      style={leftStyle}
      {...leftProps}
    >
      {left}
    </div>
    <div
      className={classnames('common__fifty-fifty__right', rightClassName)}
      style={rightStyle}
      {...rightProps}
    >
      {right}
    </div>
  </div>
)

FiftyFifty.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  className: PropTypes.string,
  leftClassName: PropTypes.string,
  rightClassName: PropTypes.string,
  leftStyle: PropTypes.object,
  rightStyle: PropTypes.object,
  reverseOnMobile: PropTypes.bool,
  hideSeparator: PropTypes.bool,
  style: PropTypes.object,
  leftProps: PropTypes.object,
  rightProps: PropTypes.object,
}

export default FiftyFifty
