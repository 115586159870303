import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from './Button'
import LanguagePicker from './LanguagePicker'
import Modal from './Modal/Modal'

const NotificationModal = ({
  isOpen,
  onClose,
  title,
  children,
  optionalBtnLabel,
  optionalBtnAction,
}) => {
  const [t] = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <LanguagePicker className="mb-md" />
      <Modal.Heading>{title}</Modal.Heading>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose()} secondary>
          {t('COMMON:CLOSE')}
        </Button>
        <Button onClick={() => optionalBtnAction()}>{optionalBtnLabel}</Button>
      </Modal.Footer>
    </Modal>
  )
}

NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  optionalBtnLabel: PropTypes.string,
  optionalBtnAction: PropTypes.func,
}

export default NotificationModal
