const companiesQueryKeys = {
  root: ['companies'],
  single: (companyId) => [...companiesQueryKeys.root, 'company', companyId],
  companiesList: (options) => [...companiesQueryKeys.root, 'list', options],
  tags: (companyId, options) => [
    ...companiesQueryKeys.single(companyId),
    'tags',
    options,
  ],
  statistics: (companyId, endDate, options) => [
    ...companiesQueryKeys.single(companyId),
    'statistics',
    endDate,
    options,
  ],
  eventsRoot: (companyId) => [
    ...companiesQueryKeys.single(companyId),
    'events',
  ],
  eventList: (companyId, startIndex) => [
    ...companiesQueryKeys.eventsRoot(companyId),
    'eventList',
    startIndex,
  ],
}

export default companiesQueryKeys
