import PropTypes from 'prop-types'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { useImmutable } from '../utility'
import InputLabel from './InputLabel'
import './RadioGroup.scss'

const RadioGroup = ({
  value,
  label,
  hasError,
  required,
  options,
  onCheck,
  ariaLabelledby,
  className,
  style,
  ...props
}) => {
  const elementId = useImmutable(`RadioGroup__${uuid()}`)

  return (
    <>
      {Boolean(label) && (
        <InputLabel
          required={required}
          style={{ marginBottom: '10px' }}
          id={elementId}
        >
          {label}
        </InputLabel>
      )}
      <div
        role="radiogroup"
        aria-labelledby={label ? elementId : ariaLabelledby}
        className={classnames('common__radio-group__group', className)}
        style={style}
        aria-required={required}
        {...props}
      >
        {options.map((option) => (
          <RadioButtonWithLabel
            key={option.value}
            label={option.label}
            checked={option.value === value}
            disabled={option.disabled}
            onCheck={() => onCheck(option.value)}
            hasError={hasError}
          />
        ))}
      </div>
    </>
  )
}

RadioGroup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCheck: PropTypes.func.isRequired,
  ariaLabelledby: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const RadioButton = ({
  checked,
  disabled,
  hasError,
  className,
  style,
}) => (
  <div
    role="radio"
    aria-checked={checked}
    className={classnames(
      'common__radio-group__radio-button',
      {
        'common__radio-group__radio-button--checked': checked,
        'common__radio-group__radio-button--disabled': disabled,
        'common__radio-group__radio-button--has-error': hasError,
      },
      className
    )}
    style={style}
  >
    <div
      className={classnames('common__radio-group__check', {
        'common__radio-group__check--checked': checked,
      })}
    />
  </div>
)

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const RadioButtonWithLabel = ({
  label,
  checked,
  disabled,
  onCheck,
  hasError,
  className,
  style,
  ...props
}) => (
  <button
    type="button"
    onClick={() => (disabled ? undefined : onCheck())}
    className="common__radio-group__option"
    style={style}
    {...props}
  >
    <RadioButton
      checked={checked}
      disabled={disabled}
      hasError={hasError}
      className={className}
    />
    <span
      className={classnames('common__radio-group__label', {
        'common__radio-group__label--disabled': disabled,
      })}
    >
      {label}
    </span>
  </button>
)

RadioButtonWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default RadioGroup
