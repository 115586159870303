import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Hr.scss'

const Hr = ({ className, style, ...props }) => (
  <hr
    className={classnames('common__hr', className)}
    style={style}
    {...props}
  />
)

Hr.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Hr
