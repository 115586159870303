import classnames from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useImmutable } from '../utility'
import Checkbox from './Checkbox'
import './CheckboxWithLabel.scss'
import { forwardRef } from 'react'

const CheckboxWithLabel = forwardRef(
  (
    {
      checked,
      label,
      onClick,
      disabled,
      hasError,
      className,
      labelClassName,
      id,
      style,
      ...props
    },
    ref
  ) => {
    const labelId = useImmutable(`CheckboxWithLabel__label__${uuid()}`)

    return (
      <div
        className={classnames('common__checkbox-with-label', className)}
        onClick={disabled ? () => {} : onClick}
        style={style}
        {...props}
      >
        <div>
          <Checkbox
            ref={ref}
            checked={checked}
            hasError={hasError}
            aria-labelledby={labelId}
            id={id}
            disabled={disabled}
          />
        </div>
        <span
          id={labelId}
          className={classnames(
            'common__checkbox-with-label__label',
            'ml-xs',
            labelClassName
          )}
        >
          {label}
        </span>
      </div>
    )
  }
)

CheckboxWithLabel.displayName = 'CheckBoxWithLabel'

CheckboxWithLabel.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
}

export default CheckboxWithLabel
