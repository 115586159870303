import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apiStore from '../../../common/stores/apiStore'

const useInvite = (inviteId) => {
  const [t] = useTranslation()
  const [invite, setInvite] = useState()
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        setIsFetching(true)

        const invite = await apiStore.endpoints.invite.getInviteById(inviteId)

        setInvite(invite)

        setIsFetching(false)
      } catch {
        window.alert(t('COMMON:ERRORS.CLIENT_ERROR'))
      }
    }

    fetchInvites()
  }, [inviteId])

  return [invite, isFetching]
}

export default useInvite
