import { addYears, startOfYear } from 'date-fns'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NotificationModal from '../../../../common/components/NotificationModal'
import { A, P } from '../../../../common/components/Typography'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  FINNISH,
} from '../../../../common/constants'
import useCompanyId from '../../../../common/hooks/useCompanyId'
import { usePersistentState } from '../../../../common/hooks/usePersistentState'
import userStore from '../../../../common/stores/userStore'
import ROUTES, { multilingualRoutesToPath } from '../../../routes/routes'
import { isDateDecember } from '../../helpers'
import useBenefitsQuery from '../../queries/useBenefitsQuery'

const YearlyBenefitSettingPopup = () => {
  const companyId = useCompanyId()
  const [t, i18n] = useTranslation()
  const navigate = useNavigate()

  const [lastSeenAt, setLastSeenAt] = usePersistentState(
    'yearlyBenefitSettingPopupSeenAt'
  )
  const [isDecember] = useState(() => isDateDecember(new Date()))
  const [currentYear] = useState(() => new Date().getFullYear())
  const firstDayOfNextYear = useMemo(
    () =>
      isDecember &&
      addYears(startOfYear(Date.now()), 1).toLocaleDateString(i18n.language, {
        month: i18n.language === FINNISH.code ? 'numeric' : 'long',
        day: 'numeric',
        year: 'numeric',
      }),
    [isDecember, i18n.language]
  )

  const isNotSeenThisDecember =
    !!companyId &&
    userStore.isCompanyAdmin &&
    lastSeenAt !== currentYear &&
    isDecember

  const { data: benefitData } = useBenefitsQuery({
    companyId,
    benefitType: [
      BENEFIT_TYPE_ANNUAL_BALANCE,
      BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
    ].toString(),
    enabled: isNotSeenThisDecember,
  })
  const hasAnyAnnualBalanceUsers =
    benefitData?.benefitTypes[BENEFIT_TYPE_ANNUAL_BALANCE] > 0 ||
    benefitData?.benefitTypes[BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE] > 0

  return (
    <NotificationModal
      isOpen={isNotSeenThisDecember && hasAnyAnnualBalanceUsers}
      onClose={() => setLastSeenAt(currentYear)}
      optionalBtnLabel={t(
        'COMPANY:COMPANY_PORTAL_SCREEN.YEARLY_SETTINGS_POPUP.BTN_LABEL'
      )}
      optionalBtnAction={() => {
        setLastSeenAt(currentYear)
        navigate(
          multilingualRoutesToPath(
            ROUTES.COMPANY_ADMIN.COMMUTER_BENEFIT__BENEFIT_SETTINGS,
            { companyId },
            {},
            i18n.language
          )
        )
      }}
      title={t('COMPANY:COMPANY_PORTAL_SCREEN.YEARLY_SETTINGS_POPUP.HEADER')}
    >
      <div className="*:m-0 space-y-md">
        <P>
          {t('COMPANY:COMPANY_PORTAL_SCREEN.YEARLY_SETTINGS_POPUP.BODY_1', {
            firstDayOfNextYear,
          })}
        </P>
        <P>
          <Trans
            i18nKey="COMPANY:COMPANY_PORTAL_SCREEN.YEARLY_SETTINGS_POPUP.BODY_2"
            components={{ a: <A /> }}
            values={{
              url: t(
                'COMPANY:COMPANY_PORTAL_SCREEN.YEARLY_SETTINGS_POPUP.INSTRUCTIONS_LINK'
              ),
              email: t(
                'COMPANY:COMPANY_PORTAL_SCREEN.CORPORATE_CUSTOMER_SERVICE_EMAIL'
              ),
              tel: t(
                'COMPANY:COMPANY_PORTAL_SCREEN.CUSTOMER_SERVICE_PHONE_NUMBER'
              ),
            }}
          />
        </P>
      </div>
    </NotificationModal>
  )
}

export default YearlyBenefitSettingPopup
